import React from "react";
import {
  Nav,
  NavLink,
  NavbarContainer,
  NavLogo,
  NavItems,
  GitHubButton,
  ButtonContainer,
  MobileMenu, // Ensure MobileMenu is imported
  MobileLink,
} from "./NavbarStyledComponent";
import { Bio } from "../../data/constants";
import styled, { useTheme } from "styled-components";

// MobileIcon and Bar styled components
const MobileIcon = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: relative;
    width: 30px;
    height: 21px;
    cursor: pointer;
    z-index: 10;
  }
`;

const Bar = styled.div`
  width: 100%;
  height: 3px;
  background-color: rgb(242, 243, 244);
  position: absolute;
  transition: all 0.3s ease;

  &:nth-child(1) {
    top: ${({ isOpen }) => (isOpen ? "9px" : "0")};
    transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
  }

  &:nth-child(2) {
    top: 9px;
    opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
  }

  &:nth-child(3) {
    top: ${({ isOpen }) => (isOpen ? "9px" : "18px")};
    transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
  }
`;

const ColorText = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 32px;
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to="/">
          <ColorText>&lt;</ColorText>DAUD
          <div style={{ color: theme.primary }}>\</div>ASIF
          <ColorText>&gt;</ColorText>
        </NavLogo>
        <MobileIcon onClick={() => setIsOpen(!isOpen)}>
          <Bar isOpen={isOpen} />
          <Bar isOpen={isOpen} />
          <Bar isOpen={isOpen} />
        </MobileIcon>
        <NavItems>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#experience">Experience</NavLink>
          <NavLink href="#projects">Projects</NavLink>
          <NavLink href="#education">Education</NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.linkedin} target="_blank">
            linkedin Profile
          </GitHubButton>
        </ButtonContainer>
        <MobileMenu isOpen={isOpen}>
          <MobileLink
            href="#about"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            About
          </MobileLink>
          <MobileLink
            href="#skills"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Skills
          </MobileLink>
          <MobileLink
            href="#experience"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Experience
          </MobileLink>
          <MobileLink
            href="#projects"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Projects
          </MobileLink>
          <MobileLink
            href="#education"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Education
          </MobileLink>
          <GitHubButton
            style={{
              padding: "10px 16px",
              background: `rgb(10, 102, 194)`,
              color: "white",
              width: "max-content",
              border: "1.8px solid rgb(10, 102, 194)"
              ,
            }}
            className="custom-github-button"
            href={Bio.linkedin}
            target="_blank"
          >
            LinkedIn Profile
          </GitHubButton>
        </MobileMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
